const questions = [
  // 1 Слайд
  {
    questionText:
      "Whew, I’m finally done with the packing! Can’t disassemble the wardrobe on my own, though.",
    answerOptions: [
      {
        answerText: "Let’s leave it here. New life, new furniture.",
        answerResult:
          "The wardrobe is pretty good, it’d be a pity to leave it behind, and my friends could be busy. I’ll use the Helper service. I’ll leave the disassembling to the professional and go through my old stuff in the meantime.",
        result: false,
      },
      {
        answerText: "I’ll call my friends for help.",
        answerResult:
          "The wardrobe is pretty good, it’d be a pity to leave it behind, and my friends could be busy. I’ll use the Helper service. I’ll leave the disassembling to the professional and go through my old stuff in the meantime.",
        result: false,
      },
      {
        answerText: "I’ll order the Helper service in the app.",
        answerResult:
          "The wardrobe is pretty good, it’d be a pity to leave it behind, and my friends could be busy. I’ll use the Helper service. I’ll leave the disassembling to the professional and go through my old stuff in the meantime.",
        result: true,
      },
    ],
    image: "images/01.webp",
  },

  // 2 слайд

  {
    questionText:
      "These I’ll keep, and these will go into the dumpster. But there’s no way I can carry all this stuff alone.",
    answerOptions: [
      {
        answerText: "Let the new residents handle the old stuff.",
        answerResult:
          "Asking the handyman for assistance is a good idea. I’ll give them a high rating for their work in the Maxim app.",
        result: false,
      },
      {
        answerText:
          "I’ll ask the handyman to take the trash out while they’re still here.",
        answerResult:
          "Asking the handyman for assistance is a good idea. I’ll give them a high rating for their work in the Maxim app.",
        result: true,
      },
      {
        answerText: "I’ll throw a garage sale in the yard.",
        answerResult:
          "Asking the handyman for assistance is a good idea. I’ll give them a high rating for their work in the Maxim app.",
        result: false,
      },
    ],
    image: "images/02.webp",
  },

  // 3 слайд
  {
    questionText:
      "Time to move my things to the new apartment! Do you think we’ll manage to do it in one go?",
    answerOptions: [
      {
        answerText: "I doubt that. Even three trucks won’t be enough here.",
        answerResult:
          "One regular truck is enough to transport this. We’re certainly gonna need some help with loading and unloading, so I’ll also order the Helper service once again.",
        result: false,
      },
      {
        answerText:
          "Come on, you don’t have that much stuff. A regular car will do.",
        answerResult:
          "One regular truck is enough to transport this. We’re certainly gonna need some help with loading and unloading, so I’ll also order the Helper service once again.",
        result: false,
      },
      {
        answerText: "Just one truck is all we need.",
        answerResult:
          "One regular truck is enough to transport this. We’re certainly gonna need some help with loading and unloading, so I’ll also order the Helper service once again.",
        result: true,
      },
    ],
    image: "images/03.webp",
  },

  // 4 слайд
  {
    questionText:
      "My friend promised to meet the driver and the mover at my new place. How will he know that they’ve arrived?",
    answerOptions: [
      {
        answerText:
          "Easy-peasy! We should calculate how long it’s probably gonna take for the truck to get there and call your buddy.",
        answerResult:
          "We can count on Pengy’s sense or do the time math, but it’s much more reliable to just send him a link to the ride. He can track the driver on the in-app online map.",
        result: false,
      },
      {
        answerText:
          "Let’s just hope that his penguin senses will start tingling.",
        answerResult:
          "We can count on Pengy’s sense or do the time math, but it’s much more reliable to just send him a link to the ride. He can track the driver on the in-app online map.",
        result: false,
      },
      {
        answerText: "Track the truck with your stuff on an online map.",
        answerResult:
          "We can count on Pengy’s sense or do the time math, but it’s much more reliable to just send him a link to the ride. He can track the driver on the in-app online map.",
        result: true,
      },
    ],
    image: "images/04.webp",
  },

  // 5 слайд
  {
    questionText:
      "I handed the keys over to the new residents. Time to move into the new apartment!",
    answerOptions: [
      {
        answerText:
          "I’ll order a bike so I can get there faster and start unpacking ASAP.",
        answerResult:
          "Moving apartments can surely be tricky. It looks like Pengy won’t be able to handle this all by himself. Let’s hurry up and save the day!",
        result: true,
      },
      {
        answerText: "I’ll take a walk through town.",
        answerResult:
          "Moving apartments can surely be tricky. It looks like Pengy won’t be able to handle this all by himself. Let’s hurry up and save the day!",
        result: false,
      },
      {
        answerText: "Come to think of it, I don’t really want to leave ...",
        answerResult:
          "Moving apartments can surely be tricky. It looks like Pengy won’t be able to handle this all by himself. Let’s hurry up and save the day!",
        result: false,
      },
    ],
    image: "images/05.webp",
  },

  // 6 слайд
  {
    questionText:
      "We’re here! Looks like the mover helped Pengy carry the stuff upstairs. How should we thank the mover for the job well done?",
    answerOptions: [
      {
        answerText: "Add him on Facebook.",
        answerResult:
          "Being friendly is nice, but the mover will enjoy a tip even more. The tip amount will be added to the order price.",
        result: false,
      },
      {
        answerText: "Leave a tip.",
        answerResult:
          "Being friendly is nice, but the mover will enjoy a tip even more. The tip amount will be added to the order price.",
        result: true,
      },
      {
        answerText: "Say “Thank you.”",
        answerResult:
          "Being friendly is nice, but the mover will enjoy a tip even more. The tip amount will be added to the order price.",
        result: false,
      },
    ],
    image: "images/06.webp",
  },
];

export default questions;
